import React, {useEffect, useState} from 'react'
import cx from "classnames";
import Head from "next/head";


function App() {

    useEffect(() => {
        let iosLink =
            'https://apps.apple.com/us/app/wayout-%D1%85%D1%83%D0%B4%D0%B5%D0%B5%D0%BC-%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D1%8C%D0%BD%D0%BE/id6463749694';
        let googleLink = 'https://play.google.com/store/apps/details?id=com.wayout.wayout_food';
        let isIphone = /^(iPhone|iPad|iPod|Mac)/.test(navigator.platform);
        let href = isIphone ? iosLink : googleLink;
        window.location.replace(href);
    });
    return (
        <>
            <Head>
                <title>APP</title>
            </Head>
            <>
                <img src="https://mc.yandex.ru/watch/86996338"/>
                <div style={{
                    background: "white",
                    position: "fixed",
                    zIndex: 1,
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                    opacity: "1",
                    transition: "all .2s",
                    visibility: "visible",
                }}><span className={cx('css-loader')}></span></div>
            </>
        </>
    )
}


export default App
